import React, { useState } from "react";

// Custom
import { StyledDivider } from "./";
import Colors from "../../constants/Colors";

const TabSelector = ({ tabs, setTab, currentTab, style }) => {
  return (
    <div
      style={{
        border: "solid 1px rgba(0, 0, 0, 0.2)",
        borderRadius: 4,
        ...style,
      }}
    >
      {tabs.map((tabName, index) => (
        <div key={index}>
          <TabItem tabIndex={index} currentTab={currentTab} setTab={setTab}>
            {tabName}
          </TabItem>
          {index !== tabs.length - 1 && <StyledDivider />}
        </div>
      ))}
    </div>
  );
};

const TabItem = ({ children, tabIndex, currentTab, setTab }) => {
  const [hovering, setHovering] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={() => {
        setTab(tabIndex);
      }}
      style={{
        background: hovering ? Colors.lightGrey : null,
        cursor: "pointer",
        borderLeft:
          tabIndex === currentTab
            ? "2px solid " + Colors.mainGreen
            : "2px solid rgba(0,0,0,0)",
      }}
    >
      <p
        style={{
          fontSize: 12,
          fontWeight: 500,
          padding: "10px 8px",
          margin: 0,
          color: Colors.darkGrey,
        }}
      >
        {children}
      </p>
    </div>
  );
};

export default TabSelector;
