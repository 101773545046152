// Third party
import React, { Suspense, lazy, useState, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";

// Custom components
import { Loading } from "../components/general";
import { getPublicPage } from "../redux/actions/organisationActions";
import { acceptOrgInvitation } from "../redux/actions/memberActions";
import { userValidateCodeRequest } from "../redux/actions/authActions";
import Colors from "../constants/Colors";

import StyledParagraph from "../components/general/StyledParagraph";
import CLMTEAdminPage from "../components/containers/CLMTEAdminPage";
import TrackCompensation from "../components/containers/TrackCompensation";
import PublicPage from "../components/containers/PublicPage";

// Lazy loading
const Footer = lazy(() => import("../components/containers/Footer"));
const Header = lazy(() => import("../components/containers/Header"));
const Home = lazy(() => import("../components/containers/Home"));
const About = lazy(() => import("../components/containers/About"));
const Services = lazy(() => import("../components/containers/Services"));
const Contact = lazy(() => import("../components/containers/Contact"));
const FAQ = lazy(() => import("../components/containers/FAQ"));
const Addon = lazy(() => import("../components/containers/AddonExamplePage"));
const AdminPage = lazy(() => import("../components/containers/adminPage/AdminPage")); // prettier-ignore
const AdminLogin = lazy(() => import("../components/containers/AdminLogin"));
const OrganisationsList = lazy(() => import("../components/organisations/OrganisationsList")); // prettier-ignore
const OrganisationPage = lazy(() => import("../components/organisations/OrganisationPage")); // prettier-ignore
const Guide = lazy(() => import("../components/containers/adminPage/Guide")); // prettier-ignore
const PageNotFound = lazy(() => import("../components/containers/PageNotFound")); // prettier-ignore

const checkUrlPage = (location, pageName) => {
  let pathname = location.pathname;
  if (pathname.slice(0, pageName.length) === pageName) {
    return true;
  }
  return false;
};

let fetchingInvitation = false;

const Boiler = ({
  isAuthenticated,
  firstTimeAuthLoading,
  acceptOrgInvitation,
  location,
  getPublicPage,
  userValidateCodeRequest,
}) => {
  const [firstRedirectDone, setFirstRedirectDone] = useState(false);
  const [renderInvitationPage, setRenderInvitationPage] = useState(null);

  let history = useHistory();

  useEffect(() => {
    // If we are trying to log in by pressing the email button with your code
    if (firstTimeAuthLoading === false) {
      if (checkUrlPage(location, "/login")) {
        let email = new URLSearchParams(location.search).get("email");
        let code = new URLSearchParams(location.search).get("emailCode");

        userValidateCodeRequest(email, code).then(
          () => {
            history.push("adminpage/organisations");
          },
          (reason) => {
            history.push("/");
            console.log(reason);
            alert(
              "Something went wrong whilst trying to log in. This may be due to your login code being invalid or expired."
            );
          }
        );
      }
    }
  }, [firstTimeAuthLoading]); // eslint-disable-line

  // If we are trying to accept an invitation to an organisation
  if (checkUrlPage(location, "/join_organisation")) {
    if (!isAuthenticated && renderInvitationPage !== "loading") {
      setRenderInvitationPage("loading");
    }
    // We need to be authenticated before checking invites
    if (isAuthenticated && fetchingInvitation === false) {
      // console.log("fetching!");
      fetchingInvitation = true;

      let code = new URLSearchParams(location.search).get("invitationCode");
      // console.log("code: " + code);

      acceptOrgInvitation(code).then(
        () => {
          setRenderInvitationPage("success");
        },
        (err) => {
          setRenderInvitationPage("error");
        }
      );
    }
  }

  // Whilst we are waiting for our token to be refreshed, show a loading screen
  if (firstTimeAuthLoading) {
    return <Loading fullScreen firstTime />;
  }

  // If we open the page and are authenticated or not, redirect
  if (firstRedirectDone === false) {
    if (isAuthenticated && location.pathname === "/") {
      return <Redirect to="/adminpage" />;
    }
    if (!isAuthenticated && checkUrlPage(location, "/adminpage")) {
      return <Redirect to="/" />;
    }
    setFirstRedirectDone(true);
  }

  // Normal website behaviour depending on URL
  if (checkUrlPage(location, "/adminpage")) {
    return <AdminPageWebsite />;
  } else if (checkUrlPage(location, "/pages")) {
    return (
      <PublicPage pathname={location.pathname} getPublicPage={getPublicPage} />
    );
  } else if (checkUrlPage(location, "/track")) {
    return (
      <TrackCompensation
        pathname={location.pathname}
        getCompensation={(msg) => console.log(msg)}
      />
    );
  } else {
    return (
      <NormalWebsite
        renderInvitationPage={renderInvitationPage}
        setRenderInvitationPage={setRenderInvitationPage}
        isAuthenticated={isAuthenticated}
      />
    );
  }
};

const NormalWebsite = ({
  renderInvitationPage,
  setRenderInvitationPage,
  isAuthenticated,
}) => (
  <Suspense fallback={<Loading fullScreen />}>
    <Header />
    <Suspense fallback={<Loading fullScreen />}>
      <Switch>
        <Route path="/" exact render={() => <Home />} />
        <Route path="/about" render={() => <About />} />
        <Route path="/services" render={() => <Services />} />
        <Route path="/contact-us" render={() => <Contact />} />
        <Route path="/faq" render={() => <FAQ />} />
        <Route path="/admin-login" render={() => <AdminLogin />} />
        <Route path="/addon" render={() => <Addon />} />
        <Route path="/clmteadminpage" render={() => <CLMTEAdminPage />} />
        <Route path="/login" render={() => <Loading fullScreen />} />
        {/* If a user wants to join a org, render the OrganisationInvitePage component instead of page not found component*/}
        {renderInvitationPage !== null ? (
          <OrganisationInvitePage
            renderInvitationPage={renderInvitationPage}
            setRenderInvitationPage={setRenderInvitationPage}
            isAuthenticated={isAuthenticated}
          />
        ) : (
          <Route render={(props) => <PageNotFound {...props} />} />
        )}
      </Switch>
    </Suspense>
    <Footer />
  </Suspense>
);

const AdminPageWebsite = () => {
  return (
    <Suspense fallback={<Loading fullScreen />}>
      <AdminPage>
        <Suspense fallback={<Loading fullScreen />}>
          <Switch>
            <Route
              path="/adminpage"
              exact
              render={() => <Redirect to="/adminpage/organisations" />}
            />
            <Route
              path="/adminpage/organisations"
              exact
              render={() => <OrganisationsList />}
            />
            <Route path="/adminpage/guide" exact render={() => <Guide />} />
            <Route
              path="/adminpage/organisations/:organisationId"
              render={(props) => <OrganisationPage {...props} />}
            />
            <Route
              path="/adminpage/orgNotFound"
              render={() => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <p
                      style={{
                        margin: "100px auto 100px auto",
                        textAlign: "left",
                        maxWidth: 300,
                      }}
                    >
                      The organisation could not be found. <br />
                      <br />
                      This is most likely due to the organisation being deleted
                      or moved somewhere else, please contact{" "}
                      <b>technicalsupport@CLMTE.com</b> if you need help.
                    </p>
                  </div>
                );
              }}
            />
            <Route render={() => <PageNotFound adminPage404 />} />
          </Switch>
        </Suspense>
        <div style={{ width: "100%", height: 100 }} />
        {/*<Footer alignCenter={false} />*/}
      </AdminPage>
    </Suspense>
  );
};

/*
const MockPages = () => {
  return (
    <Suspense fallback={<Loading fullScreen />}>
      <Route
        path={"/mockpages/idealOfSweden"}
        render={() => <IdealOfSweden />}
      />
      <Route
        path={"/mockpages/oceanbottle"}
        render={() => <OceanBottleMock />}
      />
      {<Route render={() => <PageNotFound />} />}
    </Suspense>
  );
};
*/

const OrganisationInvitePage = ({
  renderInvitationPage,
  setRenderInvitationPage,
  isAuthenticated,
}) => {
  let history = useHistory();

  if (renderInvitationPage === "loading") {
    return (
      <div style={{ width: "100%", textAlign: "center", margin: "16vh 0" }}>
        <Loading justLogo />
        <h3 style={{ color: Colors.mainGreen }}>Accepting invite...</h3>
      </div>
    );
  } else if (renderInvitationPage === "error") {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          margin: "16vh 0",
          color: Colors.error,
        }}
      >
        <h3>Something went wrong.</h3>
        <StyledParagraph>
          Here are some potential reasons why things went wrong:
        </StyledParagraph>
        <div
          style={{
            textAlign: "start",
            margin: "0 auto",
            maxWidth: 500,
            padding: "10px 30px",
          }}
        >
          <StyledParagraph>
            <b>1. You are not logged into the account that has been invited.</b>{" "}
            If this is the case, log in to your account and try pressing the
            button you received in your mail again.
          </StyledParagraph>
          <StyledParagraph>
            {" "}
            <b>2. The invitation code has expired. </b>
          </StyledParagraph>
          <StyledParagraph>
            <b>3. You are already part of this organisation. </b>
          </StyledParagraph>
        </div>

        <StyledParagraph
          onClick={() => {
            setRenderInvitationPage(null);
            if (isAuthenticated) {
              history.push("/adminpage");
            } else {
              history.push("/");
            }
          }}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          Click here to return to the home page.
        </StyledParagraph>
      </div>
    );
  } else if (renderInvitationPage === "success") {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: "30vh",
        }}
      >
        <h3>You successfully joined the organisation</h3>
        <StyledParagraph
          onClick={() => {
            setRenderInvitationPage(null);
            history.push("/");
          }}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          Click here to return to the home page.
        </StyledParagraph>
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  return {
    tokenRefreshing: state.auth.tokenRefreshing,
    isAuthenticated: state.auth.isAuthenticated,
    firstTimeAuthLoading: state.auth.firstTimeAuthLoading,
  };
};

export default connect(mapStateToProps, {
  getPublicPage,
  acceptOrgInvitation,
  userValidateCodeRequest,
})(withRouter(Boiler));
