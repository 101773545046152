import {
  ADD_ORGANISATIONS,
  ADD_DETAILED_ORGANISATION,
  CLEAR_ORGANISATIONS,
  ADD_TUNDRA_STATS_TO_ORG,
  ADD_HIGHLAND_COMPENSATIONS_TO_ORG,
} from "../actions/types";

const initalState = {
  organisations: null,
  detailedOrgs: [],
};

export default (state = initalState, action = {}) => {
  let newDetailedOrgs = [];

  switch (action.type) {
    case ADD_ORGANISATIONS:
      return {
        ...state,
        organisations: action.organisations,
      };
    case ADD_DETAILED_ORGANISATION:
      return {
        ...state,
        detailedOrgs: [...state.detailedOrgs, action.detailedOrganisation],
      };
    case ADD_TUNDRA_STATS_TO_ORG:
      state.detailedOrgs.forEach((detailedOrg) => {
        if (detailedOrg._id === action.orgId) {
          let newDetailedOrg = detailedOrg;
          if (!newDetailedOrg.tundraStatistics) {
            newDetailedOrg.tundraStatistics = [];
          }
          // Add all elements that don't already exist in the list
          newDetailedOrg.tundraStatistics = newDetailedOrg.tundraStatistics.concat(
            action.tundraStatistics.filter(
              (item) => newDetailedOrg.tundraStatistics.indexOf(item) < 0
            )
          );
          newDetailedOrgs.push(newDetailedOrg);
        } else {
          newDetailedOrgs.push(detailedOrg);
        }
      });

      return {
        ...state,
        detailedOrgs: newDetailedOrgs,
      };
    case ADD_HIGHLAND_COMPENSATIONS_TO_ORG:
      state.detailedOrgs.forEach((detailedOrg) => {
        if (detailedOrg._id === action.orgId) {
          let newDetailedOrg = detailedOrg;
          if (!newDetailedOrg.highlandCompensations) {
            newDetailedOrg.highlandCompensations = [];
          }
          // Add all elements that don't already exist in the list
          newDetailedOrg.highlandCompensations = newDetailedOrg.highlandCompensations.concat(
            action.highlandCompensations.filter(
              (item) => newDetailedOrg.highlandCompensations.indexOf(item) < 0
            )
          );
          newDetailedOrgs.push(newDetailedOrg);
        } else {
          newDetailedOrgs.push(detailedOrg);
        }
      });

      return {
        ...state,
        detailedOrgs: newDetailedOrgs,
      };
    case CLEAR_ORGANISATIONS:
      return initalState;
    default:
      return state;
  }
};
