import { clearSession, refreshAccessToken } from "./actions/authActions";

export default function jwtMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    // only worry about expiring token for async actions
    if (typeof action === "function") {
      let accessToken = localStorage.getItem("accessToken");
      let expiresAt = localStorage.getItem("accessTokenExpiresAt");

      if (getState().auth && accessToken) {
        // console.log("We have auth and an access token, expires in:");
        // console.log(expiresAt - Date.now());
        // console.log(expiresAt - Date.now() < 300000);

        // decode jwt so that we know if and when it expires
        let fiveMinutes = 300000;
        if (expiresAt && expiresAt - Date.now() < fiveMinutes) {
          // make sure we are not already refreshing the token
          if (!getState().auth.freshTokenPromise) {
            return refreshAccessToken(dispatch).then(
              () => next(action),
              () => {
                console.log(
                  "Failed to refresh token since it most likely expired, clearing session."
                );
                clearSession();
              }
            );
          } else {
            return getState().auth.freshTokenPromise.then(() => next(action));
          }
        }
      }
    }
    return next(action);
  };
}
