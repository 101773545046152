import React from "react";
import { TextField, Grid } from "@material-ui/core";
import Colors from "../../constants/Colors";
import InfoDropDown from "./InfoDropDown";

const StyledTextField = ({
  descText,
  error,
  errorText,
  style,
  onChange,
  name,
  label,
  textFieldProps,
  InputProps,
  value = null,
}) => {
  return (
    <Grid container style={{ width: "100%" }}>
      <Grid item xs={descText ? 11 : 12}>
        <TextField
          {...textFieldProps}
          inputProps={{
            style: {
              fontSize: 15,
              borderColor: Colors.grey,
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: 15,
              fontWeight: 500,
              color: Colors.grey,
            },
          }}
          id={name + Math.random()}
          name={name}
          onChange={onChange}
          variant="outlined"
          margin="normal"
          fullWidth
          size="small"
          type="text"
          label={label}
          error={error}
          style={{ margin: 0, color: Colors.grey, ...style }}
          InputProps={InputProps}
          value={value}
        />
        {error ? (
          <p style={{ color: "red", fontSize: 12, margin: "2px 0 8px 0" }}>
            {errorText}
          </p>
        ) : (
          <div
            style={{
              height: 26,
              width: "100%",
            }}
          />
        )}
      </Grid>
      <DescTextDropDown descText={descText} label={label} />
    </Grid>
  );
};

const DescTextDropDown = ({ descText, label }) => {
  return (
    <>
      {descText ? (
        <Grid item xs={1} style={{ paddingTop: 10 }}>
          <InfoDropDown iconSize="large" title={label}>
            {descText}
          </InfoDropDown>
        </Grid>
      ) : null}
    </>
  );
};

export default StyledTextField;
