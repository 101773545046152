import React from "react";
import { NavLink } from "react-router-dom";
import Colors from "../../constants/Colors";

export default ({
  to = "#",
  style,
  activeStyle,
  onClick = () => {},
  children,
}) => (
  <NavLink
    to={to}
    style={{
      color: Colors.grey,
      fontSize: 16,
      textDecoration: "none",
      fontWeight: 500,
      ...style,
    }}
    onClick={onClick}
    activeStyle={{
      color: "black",
      ...activeStyle,
    }}
  >
    {children}
  </NavLink>
);
