import React from "react";
// Custom
import Colors from "../../constants/Colors";

export default ({ children, colored, href, style }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    style={{
      color: colored !== undefined ? Colors.link : "grey",
      fontSize: 15,
      fontWeight: 500,
      textDecoration: "none",
      ...style,
    }}
  >
    {children}
  </a>
);
