import React from "react";
// Custom
import Colors from "../../constants/Colors";

export default (props) => {
  return (
    <p
      {...props}
      style={{
        color: Colors.darkGrey,
        fontWeight: 500,
        fontSize: 14,
        margin: "14px 0",
        ...props.style,
      }}
    >
      {props.children}
    </p>
  );
};
