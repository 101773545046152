import React, { useState, useEffect, useRef } from "react";
import { Grid, Button } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { connect } from "react-redux";

import { DropDown, StyledDivider } from "../general";
import { getCategories } from "../../redux/actions/categoryActions";

import Colors from "../../constants/Colors";
import StyledParagraph from "../general/StyledParagraph";

const CategoryDropDown = ({
  canOnlySelectOneCategory = false,
  disabled,
  addCategory,
  removeCategory,
  formCategories,
  isTestDropDown = false,
  // Redux
  getCategories,
  allCategories,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [fetchingCategories, setFetchingCategories] = useState(false);
  const [widthOfDropDown, setWidthOfDropDown] = useState(0);
  const dropDownContainerRef = useRef(null);

  if (allCategories === null && fetchingCategories === false) {
    setFetchingCategories(true);
    getCategories();
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    if (dropDownContainerRef.current !== null) {
      if (dropDownContainerRef.current.offsetWidth !== 0) {
        setWidthOfDropDown(dropDownContainerRef.current.offsetWidth);
      }
    }

    prevOpen.current = open;
  }, [open, dropDownContainerRef.current]); // eslint-disable-line

  return (
    <DropDown
      open={open}
      handleClose={handleClose}
      handleToggle={() => {
        if (disabled === false) {
          handleToggle();
        }
      }}
      disabled={disabled}
      anchorRef={anchorRef}
      style={{ zIndex: 900, margin: 0, width: "100%" }}
      popperStyle={{
        maxHeight: 400,
        overflow: "scroll",
        boxShadow: "0 0 15px rgba(0,0,0,0.14)",
      }}
      icon={
        <div ref={dropDownContainerRef} style={{ width: "100%" }}>
          <DropDownContainer
            open={open}
            formCategories={formCategories}
            canOnlySelectOneCategory={canOnlySelectOneCategory}
          />
        </div>
      }
    >
      {allCategories !== null ? (
        <div style={{ width: widthOfDropDown }}>
          {allCategories.map((category, index) => {
            if (category.parent === undefined) {
              return (
                <ListItem
                  key={index}
                  last={index + 1 === allCategories.length}
                  isTestDropDown={isTestDropDown}
                  handleClose={handleClose}
                  canOnlySelectOneCategory={canOnlySelectOneCategory}
                  category={category}
                  addCategory={addCategory}
                  removeCategory={removeCategory}
                  formCategories={formCategories}
                  allCategories={allCategories}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      ) : null}
    </DropDown>
  );
};

const ListItem = ({
  handleClose,
  last,
  isTestDropDown,
  canOnlySelectOneCategory,
  category,
  addCategory,
  removeCategory,
  formCategories,
  allCategories,
}) => {
  const [added, setAdded] = useState(isTestDropDown ? true : false);
  const [showingChildren, setShowingChildren] = useState(false);

  const isParent = category.parent === undefined;

  useEffect(() => {
    if (formCategories !== null) {
      formCategories.forEach((formCategory) => {
        if (formCategory._id === category._id) {
          setAdded(true);
        }
      });
    }
  }, [category, formCategories, setAdded]);

  const handleItemClicked = (e) => {
    if (canOnlySelectOneCategory) {
      addCategory(category);
      handleClose(e);
    }
  };

  const handleShowChildren = () => {
    if (isParent) {
      setShowingChildren(!showingChildren);
    }
  };

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        onClick={handleItemClicked}
        style={{
          padding: "4px 16px",
          minWidth: 160,
          fontSize: 15,
          fontWeight: 500,
          marginRight: 20,
          cursor: canOnlySelectOneCategory ? "pointer" : "default",
          background: isParent ? "white" : Colors.lightGrey,
        }}
      >
        <Grid item>
          <Grid
            container
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleShowChildren();
            }}
          >
            <Grid item>
              <StyledParagraph style={{ margin: 0, fontSize: 12 }}>
                {category.name}
              </StyledParagraph>
            </Grid>
            <Grid item>
              <ArrowForwardIosIcon
                style={{
                  transform:
                    showingChildren && isParent ? "rotate(90deg)" : null,
                  fontSize: 12,
                  marginTop: 3,
                  marginLeft: 6,
                  color: Colors.darkGrey,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {!canOnlySelectOneCategory && (
          <Grid item>
            {added ? (
              <Button
                variant="contained"
                style={{ padding: 0, margin: 0 }}
                onClick={(e) => {
                  removeCategory(category._id);
                  setAdded(false);
                }}
              >
                <StyledParagraph style={{ margin: 0, fontSize: 12 }}>
                  Remove
                </StyledParagraph>
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{ padding: 0, margin: 0 }}
                color="primary"
                onClick={(e) => {
                  addCategory(category);
                  setAdded(true);
                }}
              >
                <StyledParagraph
                  style={{ margin: 0, fontSize: 12, color: "white" }}
                >
                  Add
                </StyledParagraph>
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      {showingChildren &&
        allCategories.map((childCat, index) => {
          if (childCat.parent === category._id) {
            return (
              <ListItem
                key={index}
                last={index + 1 === allCategories.length}
                isTestDropDown={isTestDropDown}
                handleClose={handleClose}
                canOnlySelectOneCategory={canOnlySelectOneCategory}
                category={childCat}
                addCategory={addCategory}
                removeCategory={removeCategory}
                formCategories={formCategories}
              />
            );
          } else {
            return null;
          }
        })}
      {!last && <StyledDivider />}
    </>
  );
};

const DropDownContainer = ({
  open,
  ref,
  formCategories,
  canOnlySelectOneCategory,
}) => {
  return (
    <Grid
      ref={ref}
      container
      alignItems="center"
      style={{ margin: "0 5px 0 0" }}
    >
      <Grid item>
        <p
          style={{
            color: Colors.grey,
            fontSize: 15,
            fontWeight: 500,
            paddingRight: 12,
            margin: 0,
          }}
        >
          {canOnlySelectOneCategory ? (
            <>
              {formCategories.length !== 0
                ? formCategories[0].code
                : "Add Categories"}
            </>
          ) : (
            "Add Categories"
          )}
        </p>
      </Grid>
      <Grid item>
        <ArrowForwardIosIcon
          style={{
            transform: open ? "rotate(90deg)" : null,
            fontSize: 15,
            marginTop: 6,
            color: Colors.grey,
          }}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    allCategories: state.categories,
  };
};

export default connect(mapStateToProps, { getCategories })(CategoryDropDown);
