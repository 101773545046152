import React from "react";
import ReactMarkdown from "react-markdown";
// Custom

// Simple changes the markdown to fit our graphical profile better
export default ({ style, textStyle, source }) => (
  <div style={style}>
    <ReactMarkdown
      style={{ margin: 0 }}
      source={source}
      renderers={{
        strong: (props) => (
          <b style={{ fontWeight: 700, ...textStyle }}>{props.children}</b>
        ),
        root: (props) => (
          <p style={{ fontWeight: 500, ...textStyle }}>{props.children}</p>
        ),
      }}
      disallowedTypes={["paragraph"]}
      unwrapDisallowed
    />
  </div>
);
