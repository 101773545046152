import { SET_FETCHING_PRODUCT_ID_DATA, ADD_PRODUCT } from "../actions/types";

const initalState = {
  products: [],
  fetchingProductIdData: false,
};

export default (state = initalState, action = {}) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return { ...state, products: [...state.products, action.product] };
    case SET_FETCHING_PRODUCT_ID_DATA:
      return { ...state, fetchingProductIdData: action.isFetching };
    default:
      return state;
  }
};
