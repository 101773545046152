import React from "react";
// Custom
import getWindowSize from "../../util/getWindowSize";
import breakPoints from "../../constants/BreakPoints";

export default ({ children, style, small = false }) => {
  let fontSize = 60;
  const windowSize = getWindowSize();

  if (windowSize.width > breakPoints.xs) {
    fontSize = small ? 32 : 40;
  } else {
    fontSize = small ? 22 : 28;
  }

  return (
    <h1 style={{ fontSize: fontSize, marginBottom: 10, ...style }}>
      {children}
    </h1>
  );
};
