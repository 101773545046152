import React from "react";

const StyledDivider = ({ style, withMargin }) => {
  return (
    <div
      style={{
        width: "100%",
        height: 1,
        background: "rgba(0,0,0,0.2)",
        ...style,
        marginTop: withMargin ? 10 : 0,
      }}
    />
  );
};

export default StyledDivider;
