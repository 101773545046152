import axios from "axios";
import setAuthorizationToken from "../../util/setAuthorizationToken";
import jwt from "jsonwebtoken";

import {
  SET_CURRENT_USER,
  DONE_REFRESHING_TOKEN,
  REFRESHING_TOKEN,
  CLEAR_ORGANISATIONS,
} from "./types";

export function checkIfEmailTaken(email) {
  return (dispatch) => {
    return axios.get("/user", {
      params: {
        email: email,
      },
    });
  };
}

export function requestCode(data) {
  return (dispatch) => {
    return axios.post("/user/auth/email/send_code", data);
  };
}

export function userSignUpRequest(userData) {
  return (dispatch) => {
    return axios.post("/user", userData);
  };
}

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

// If there is a correct auth token set by setAuthorizationToken() this will work
export function getCurrentUser() {
  return axios.get("/user/@me");
}

export function userValidateCodeRequest(email, code) {
  let token = btoa(`${email}:${code}`);
  let data = { grant_type: "client_credentials" };
  let config = {
    headers: {
      Authorization: `Email ${token}`,
    },
  };
  return (dispatch) =>
    new Promise(function (resolve, reject) {
      axios.post("/user/oauth/token", data, config).then(
        (res) => {
          // Get user data
          let accessToken = res.data.access_token;
          saveAccessToken(accessToken);

          let refreshToken = res.data.refresh_token;
          localStorage.setItem("refreshToken", refreshToken);

          getCurrentUser().then(
            (userData) => {
              resolve();
              dispatch(setCurrentUser(userData.data));
            },
            (err) => {
              reject(err);
            }
          );
        },
        (err) => {
          reject(err);
        }
      );
    });
}

export function saveAccessToken(accessToken) {
  let expiresAtInSec = jwt.decode(accessToken).exp;
  let expiresAtInMS = expiresAtInSec * 1000;

  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("accessTokenExpiresAt", expiresAtInMS);
  setAuthorizationToken(accessToken);
}

export function fetchAccessToken() {
  let refreshToken = localStorage.getItem("refreshToken");
  let data = { grant_type: "refresh_token", refresh_token: refreshToken };
  return axios.post("/user/oauth/token", data);
}

export function refreshAccessToken(dispatch) {
  var freshTokenPromise = fetchAccessToken().then(
    (res) => {
      let accessToken = res.data.access_token;

      dispatch({ type: DONE_REFRESHING_TOKEN });

      saveAccessToken(accessToken);

      return accessToken
        ? Promise.resolve(accessToken)
        : Promise.reject({
            message: "could not refresh token",
          });
    },
    (e) => {
      console.log("error refreshing token", e);

      dispatch({
        type: DONE_REFRESHING_TOKEN,
      });
      return Promise.reject(e);
    }
  );

  dispatch({
    type: REFRESHING_TOKEN,
    // we want to keep track of token promise in the state so that we don't try to refresh
    // the token again while refreshing is in process
    freshTokenPromise,
  });

  return freshTokenPromise;
}

export function logout() {
  return (dispatch) => {
    clearSession();
    dispatch(setCurrentUser({}));
    dispatch({ type: CLEAR_ORGANISATIONS });
  };
}

export function clearSession() {
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("accessTokenExpiresAt");
  setAuthorizationToken(false);
}
