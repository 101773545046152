// Third party
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";

// Custom components
import {
  Logo,
  DividerTitle,
  StyledLink,
  Loading,
  StyledNavLink,
} from "../general";
import Colors from "../../constants/Colors";

import StyledParagraph from "../general/StyledParagraph";
import { getParameterByName } from "../../util/getParameterByName";
import Terrain from "../proceduralWorldGen/Terrain";
import Vegetation from "../proceduralWorldGen/Vegetation";
import getNoise from "../proceduralWorldGen/getNoise";
import { Grid } from "@material-ui/core";

import { getTundraCompensation } from "../../redux/actions/compensationActions";
import validator from "validator";

/* compensation ex
{
    organisation: {name: "string"},
    status: "CREATED" || "DELETED" || "LOCKED" || "COMPENSATED"
}
*/

const TrackCompensation = ({ getTundraCompensation }) => {
  const [compensation, setCompensation] = useState(null);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(true);

  const width = window.innerWidth + 40;
  const height = window.innerHeight + 40;
  const noise = getNoise(height, width);

  useEffect(() => {
    // Fetch compensation info once
    let trackingId = getParameterByName("trackingId");
    if (validator.isUUID(trackingId)) {
      setAmount(getParameterByName("amount"));
      getTundraCompensation(trackingId).then(
        (res) => {
          setCompensation(res.data);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, []); // eslint-disable-line

  if (loading === true) {
    return <Loading fullScreen />;
  }

  return (
    <div>
      <div
        style={{
          background: "white",
          width: "90%",
          maxWidth: 800,
          borderRadius: 6,
          margin: "10vh auto",
          padding: 20,
        }}
      >
        {compensation === null ? (
          <div style={{ width: "100%" }}>
            <StyledParagraph
              style={{
                color: Colors.error,
                fontSize: 18,
                fontWeight: 600,
                width: "100%",
                textAlign: "center",
              }}
            >
              Hmm...
            </StyledParagraph>
            <StyledParagraph
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              Something went wrong whilst trying to fetch your compensation, try
              again later.
            </StyledParagraph>
            <GoHome />
          </div>
        ) : (
          <CompensationInfo compensation={compensation} amount={amount} />
        )}
      </div>

      <div
        style={{
          width: "110vw",
          height: "110vh",
          top: -30,
          left: -30,
          position: "absolute",
          zIndex: -99,
          backgroundColor: "#86d987",
          webkitFilter: "blur(2px)",
          mozFilter: "blur(2px)",
          oFilter: "blur(2px)",
          msFilter: "blur(2px)",
          filter: "blur(2px)",
        }}
      >
        <Terrain width={width} height={height} noise={noise} />
        <Vegetation width={width} height={height} noise={noise} />
      </div>
    </div>
  );
};

const CompensationInfo = ({ compensation, amount }) => {
  return (
    <>
      <div style={{ textAlign: "center", maxWidth: 300, margin: "auto" }}>
        <NavLink to="/" style={{ marginLeft: 20 }}>
          <Logo />
        </NavLink>
        <StyledParagraph>
          <span style={{ color: Colors.darkerGreen }}>Thank you!</span> You are
          now a step closer carbon neutrality!
        </StyledParagraph>
      </div>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <DividerTitle style={{ marginBottom: 0 }} small title="Your Impact" />
          <StyledParagraph>
            You have neutralised{" "}
            <span style={{ fontWeight: 600 }}>{amount} kilograms</span> of
            carbon dioxide from the air
            {compensation.organisation.name && (
              <>
                , carbon offsetting your purchase from{" "}
                <span style={{ fontWeight: 600 }}>
                  {compensation.organisation.name}
                </span>
              </>
            )}
            .
          </StyledParagraph>
          <StyledParagraph style={{ fontSize: 12 }}>
            If you want see your carbon offset go from your purchase all the way
            to funding a carbon offset project,{" "}
            <span style={{ fontWeight: 600 }}>save or bookmark this page!</span>
          </StyledParagraph>
        </Grid>
        <Grid item xs={6}>
          <DividerTitle
            style={{ marginBottom: 0 }}
            small
            title="Climate Projects"
          />
          <StyledParagraph>
            CLMTE only funds{" "}
            <span style={{ fontWeight: 600 }}>
              U.N Gold Standard Initiatives
            </span>{" "}
            ranging from investing in renewable energy to reforestation.
          </StyledParagraph>
          <StyledParagraph style={{ fontSize: 12 }}>
            These projects all decrease greenhouse gas emissions locally. Many
            of them also contribute to the well-being of humans, for instance
            projects that replace peoples' inefficent cooking equipment with
            modern ones that require less wood and let out less CO2.
          </StyledParagraph>
          <StyledLink
            href="https://registry.goldstandard.org/projects?q=&page=1&is_certified_project=true"
            colored
          >
            See more projects here.
          </StyledLink>
        </Grid>
      </Grid>
      <div>
        <DividerTitle small title="Offset Progress" />
        <StatusIndicator status={compensation.status} />
      </div>
      <div style={{ marginTop: 20 }}>
        <StyledParagraph
          style={{
            fontSize: 12,
            textAlign: "center",
            marginBottom: 6,
            fontWeight: 600,
          }}
        >
          Don't forget to share this!
        </StyledParagraph>
        <Grid container justify="center" spacing={1}>
          <Grid item>
            <InstagramIcon />
          </Grid>
          <Grid item>
            <FacebookIcon />
          </Grid>
          <Grid item>
            <TwitterIcon />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const StatusIndicator = ({ status }) => {
  const barLength =
    status === "COMPENSATED"
      ? "100%"
      : status === "LOCKED"
      ? "70%"
      : status === "CREATED"
      ? "25%"
      : status === "DELETED"
      ? 0
      : 0;

  const text =
    status === "COMPENSATED"
      ? "Your carbon offset has successfully contributed to a greener planet!"
      : status === "LOCKED"
      ? "Your carbon offset is ready to be sent to a carbon offsetting project!"
      : status === "CREATED"
      ? "Your carbon offset has been processed and will be sent to a carbon offsetting project at the beginning of next month."
      : status === "DELETED"
      ? "Your carbon offset has been deleted. Perhaps due to your purchase being refunded or cancelled?"
      : "";
  return (
    <>
      <StyledParagraph>{text}</StyledParagraph>
      <Grid container justify="space-between">
        <Emoji emoji="🛒" />
        <Emoji emoji="🛍" />

        <Emoji emoji="💸💨" style={{ transform: "scale(-1, 1)" }} />
        <Emoji emoji="✉️💨" style={{ transform: "scale(-1, 1)" }} />
        <Emoji emoji="💸💨" style={{ transform: "scale(-1, 1)" }} />
        <Emoji emoji="✉️💨" style={{ transform: "scale(-1, 1)" }} />

        <Emoji emoji="🌳" />
        <Emoji emoji="💚⚡" />
        <Emoji emoji="🌲" />
      </Grid>
      <div
        style={{
          width: "100%",
          height: 8,
          borderRadius: 4,
          background: Colors.lightGrey,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: barLength,
            height: "100%",
            background: Colors.darkerGreen,
          }}
        />
      </div>
    </>
  );
};

const GoHome = () => {
  return (
    <Grid container justify="center" style={{ width: "100%" }}>
      <Grid item>
        <StyledNavLink
          to="/"
          style={{ color: Colors.mainGreen, marginTop: 12 }}
        >
          CLMTE Home Page
        </StyledNavLink>
      </Grid>
    </Grid>
  );
};

const Emoji = ({ emoji, style }) => {
  return (
    <span style={style} role="img" aria-label="emoji">
      {emoji}
    </span>
  );
};

export default connect((state) => state, { getTundraCompensation })(
  TrackCompensation
);
