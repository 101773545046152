// Third party
import React, { useState } from "react";

// Custom components
import { StyledTextField, LoadingButton } from "../general";
import CategoryDropdown from "../features/CategoryDropdown";
import { connect } from "react-redux";
import {
  removeCategory,
  addCategory,
} from "../../redux/actions/categoryActions";

const CLMTEAdminPage = ({ removeCategory, addCategory }) => {
  const [loading, setLoading] = useState(false);
  const [parentName, setParentName] = useState("");
  const [name, setName] = useState("");
  const [SEKPerKgCarbonDioxide, setSEKPerKgCarbonDioxide] = useState(null);

  const handleRemoveCategory = (id) => {
    removeCategory(id).then(() => {
      window.location.reload();
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (SEKPerKgCarbonDioxide === null && !isNaN(SEKPerKgCarbonDioxide)) {
      alert("Error with SEK Per Kg Carbon Dioxide, try again");
    } else if (parentName === "") {
      alert("Enter a parent category name");
    } else {
      let category = {
        parentName,
        name,
        SEKPerKgCarbonDioxide: Number(SEKPerKgCarbonDioxide),
      };
      // console.log(category);
      addCategory(category);
    }
    setLoading(false);
  };

  return (
    <div style={{ padding: "5%", display: "flex", marginTop: 10 }}>
      <div style={{}}>
        <form
          style={{
            width: "95%",
          }}
          onSubmit={handleSubmit}
        >
          <h1>Add Category</h1>
          <p style={{ fontSize: 12 }}>
            Add a new category by entering the name of the parent category (eg.
            Leisure) and then the category's name (eg. Vacation).
          </p>
          <p style={{ fontSize: 12 }}>
            If you just want to add a parent category only fill that field and
            submit.
          </p>
          <StyledTextField
            name="parent category name"
            label="parent category name"
            onChange={(e) => setParentName(e.target.value)}
          />
          <StyledTextField
            name="category name"
            label="category name"
            onChange={(e) => setName(e.target.value)}
          />
          <p>Use dots for decimals, not commas.</p>
          <StyledTextField
            name="SEKPerKgCarbonDioxide"
            label="SEK per kg Carbon Dioxide"
            onChange={(e) => setSEKPerKgCarbonDioxide(e.target.value)}
          />
          <p>Category object will be:</p>
          <code>
            {"{"}
            {`
            parentName: "${parentName}", 
            name: "${name}", 
            SEKPerKgCarbonDioxide: ${SEKPerKgCarbonDioxide}
          `}
            {"}"}
          </code>

          <LoadingButton
            style={{ marginTop: 20 }}
            text={"Add Category/Parent Category"}
            loading={loading}
          />
        </form>
      </div>

      <div
        style={{
          width: "95%",
        }}
      >
        <h1>Current categories:</h1>{" "}
        <p style={{ fontSize: 12 }}>
          Here you can see all the existing categories in the same way that the
          user will see them. Remove one from the database by pressing the
          remove button next on the one you want to remove.
        </p>
        <CategoryDropdown
          isTestDropDown={true}
          disabled={false}
          addCategory={() => {}}
          removeCategory={handleRemoveCategory}
          formCategories={[]}
        />
      </div>
    </div>
  );
};

export default connect((state) => state, { removeCategory, addCategory })(
  CLMTEAdminPage
);
