export const ADD_FLASH_MESSAGE = "ADD_FLASH_MESSAGE";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const DONE_REFRESHING_TOKEN = "DONE_REFRESHING_TOKEN";
export const REFRESHING_TOKEN = "REFRESHING_TOKEN";
export const FIRST_TIME_AUTH_DONE = "FIRST_TIME_AUTH_DONE";

export const ADD_ORGANISATIONS = "ADD_ORGANISATIONS";
export const ADD_DETAILED_ORGANISATION = "ADD_DETAILED_ORGANISATION";
export const CLEAR_ORGANISATIONS = "CLEAR_ORGANISATIONS";
export const ADD_TUNDRA_STATS_TO_ORG = "ADD_TUNDRA_STATS_TO_ORG";
export const ADD_HIGHLAND_COMPENSATIONS_TO_ORG = "ADD_HIGHLAND_COMPENSATIONS_TO_ORG"; // prettier-ignore

export const ADD_CATEGORIES = "ADD_CATEGORIES";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const SET_FETCHING_PRODUCT_ID_DATA = "SET_FETCHING_PRODUCT_ID_DATA";
