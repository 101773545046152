// Development URLs
/*
export const tellusURL = "https://devapi-clmte.kelszo.com/";
*/

// Production URLs
/*
export const tellusURL = "https://api.tellus.clmte.com/"
*/

export const tellusURL = "https://api.tellus.clmte.com/";
