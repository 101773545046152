import axios from "axios";

export function checkIfMemberExists(email) {
  return (dispatch) => {
    return axios.get("/user", {
      params: {
        email,
      },
    });
  };
}

export function sendInvite(orgId, email, role) {
  return (dispatch) => {
    return axios.post("/organisation/" + orgId + "/send_invite", {
      email,
      role,
    });
  };
}

export function acceptOrgInvitation(inviteCode) {
  return (dispatch) => {
    return axios.post("/organisation/" + inviteCode + "/join", {});
  };
}

export function editMember(orgID, userID, role) {
  return (dispatch) => {
    return axios.patch("/organisation/" + orgID + "/member/" + userID, {
      role,
    });
  };
}

export function removeMember(orgID, userID) {
  return (dispatch) => {
    return axios.delete("/organisation/" + orgID + "/member/" + userID);
  };
}
