// Third party
import React, { Suspense, lazy, useState, useEffect } from "react";

// Custom components
import { Loading, AlignCenter } from "../../components/general";

import getNoise from "../proceduralWorldGen/getNoise";
import Terrain from "../proceduralWorldGen/Terrain";
import Vegetation from "../proceduralWorldGen/Vegetation";

const PageNotFound = lazy(() => import("../../components/containers/PageNotFound")); // prettier-ignore

const PublicPage = ({ pathname, getPublicPage }) => {
  const [loading, setLoading] = useState(true);
  const [publicPage, setPublicPage] = useState(null);

  const identifier = pathname.substring("/pages/".length, pathname.length);

  const width = window.innerWidth + 40;
  const height = window.innerHeight + 40;
  const noise = getNoise(height, width);

  useEffect(() => {
    getPublicPage(identifier).then(
      (res) => {
        setLoading(false);
        setPublicPage(res.data);
        // console.log(res.data);
      },
      (err) => {
        setLoading(false);
      }
    );
  }, [getPublicPage, identifier]);

  return (
    <Suspense fallback={<Loading fullScreen />}>
      {loading && <Loading fullScreen />}
      {!loading && publicPage === null && <PageNotFound />}
      {!loading && publicPage !== null && (
        <div
          style={{
            minHeight: "100vh",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <AlignCenter>
            <div
              style={{
                background: "white",
                borderRadius: 8,
                padding: 30,
                margin: "10vh auto",
              }}
            >
              <h2
                style={{
                  marginBottom: 10,
                  marginTop: 12,
                  fontSize: 28,
                  color: "rgba(0,0,0,0.6)",
                }}
              >
                {publicPage.name} has offset
              </h2>
              <h1
                style={{
                  fontSize: 56,
                  marginTop: -10,
                  marginBottom: 0,
                  color: "black",
                }}
              >
                {(publicPage.carbonDioxideCompensated / 1000).toFixed(2)} Tonnes
                of CO
                <span style={{ fontSize: 32 }}>2</span>
              </h1>
              <h2
                style={{
                  marginTop: 6,
                  fontSize: 20,
                  color: "black",
                  marginBottom: 30,
                }}
              >
                which is equal to...
                <span style={{ fontSize: 16, verticalAlign: "top" }}> *</span>
              </h2>
              <Statistic
                multiplier={0.21604396551 * 360}
                carbonDioxideCompensated={publicPage.carbonDioxideCompensated}
                text=" cars driven to work"
              />
              <Statistic
                multiplier={1101.86280948}
                carbonDioxideCompensated={publicPage.carbonDioxideCompensated}
                text=" pounds of coal"
              />
              <Statistic
                multiplier={127532.222142}
                carbonDioxideCompensated={publicPage.carbonDioxideCompensated}
                text=" smartphones charged"
              />
              <Statistic
                multiplier={0.16930603448}
                carbonDioxideCompensated={publicPage.carbonDioxideCompensated}
                text=" homes’ electricity use for one year"
              />
              <p
                style={{
                  marginTop: 30,
                  fontWeight: 500,
                  color: "rgba(0,0,0,0.5)",
                }}
              >
                * According to the Environmental Protection Agency
              </p>
            </div>
          </AlignCenter>
          <div
            style={{
              width: "110vw",
              height: "110vh",
              top: -30,
              left: -30,
              position: "absolute",
              zIndex: -99,
              backgroundColor: "#86d987",
              webkitFilter: "blur(2px)",
              mozFilter: "blur(2px)",
              oFilter: "blur(2px)",
              msFilter: "blur(2px)",
              filter: "blur(2px)",
            }}
          >
            <Terrain width={width} height={height} noise={noise} />
            <Vegetation width={width} height={height} noise={noise} />
          </div>
        </div>
      )}
    </Suspense>
  );
};

const Statistic = ({ multiplier, carbonDioxideCompensated, text }) => {
  return (
    <h2 style={{ color: "black", fontWeight: 600 }}>
      {numberWithCommas(
        (multiplier * (carbonDioxideCompensated / 1000)).toFixed(0)
      )}
      <span style={{ color: "rgba(0,0,0,0.5)" }}>{text}</span>
    </h2>
  );
};

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ", ");
};

export default PublicPage;
