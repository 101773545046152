const mainGreen = "#20ba7a";
const darkerGreen = "#3aa67c";
const darkGreen = "#01511e";
const lightGreen = "#daf6eb";
const lightGrey = "#ededed";
const grey = "#9c9c9c";
const darkGrey = "#363636";
const red = "#FF0000";
const yellow = "#ffd966";
const blue = "#3aabc2";
const sky = "#d3ecff";

export default {
  mainGreen,
  darkerGreen,
  darkGreen,
  lightGreen,
  lightGrey,
  grey,
  darkGrey,
  link: mainGreen,
  error: red,
  yellow,
  blue,
  sky,
};
