import React from "react";
import Colors from "../../constants/Colors";

const StyledCode = (props) => {
  const { children, inline, style } = props;
  if (inline) {
    return (
      <span
        {...props}
        style={{
          backgroundColor: Colors.lightGrey,
          padding: 3,
          borderRadius: 3,
          ...style,
        }}
      >
        <code
          style={{
            wordBreak: "break-word",
            fontSize: 14,
          }}
        >
          {children}
        </code>
      </span>
    );
  } else {
    return (
      <div
        {...props}
        style={{
          backgroundColor: Colors.lightGrey,
          padding: 3,
          borderRadius: 3,
          ...style,
        }}
      >
        <code
          style={{
            wordBreak: "break-word",
            fontSize: 14,
          }}
        >
          {children}
        </code>
      </div>
    );
  }
};

export default StyledCode;
