import React, { useState } from "react";

// Custom
import { DropDown } from ".";
import Colors from "../../constants/Colors";
import StyledParagraph from "./StyledParagraph";

const InfoDropDown = ({
  children,
  iconSize = "small",
  title,
  large = false,
  iconStyle,
  popperStyle,
}) => {
  const [open, setDDOpen] = useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setDDOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setDDOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <DropDown
      open={open}
      handleClose={handleClose}
      handleToggle={handleToggle}
      anchorRef={anchorRef}
      popperStyle={popperStyle}
      icon={
        <div
          onClick={() => {
            setDDOpen(true);
            handleToggle();
          }}
          style={{
            float: "left",
            color: Colors.grey,
            border: "1px solid grey",
            cursor: "pointer",
            borderRadius: 20,
            width: iconSize === "large" ? 16 : 13,
            height: iconSize === "large" ? 16 : 13,
            textAlign: "center",
            fontSize: iconSize === "large" ? 14 : 11,
            marginTop: iconSize === "large" ? 0 : 2,
            marginLeft: iconSize === "large" ? 8 : 6,
            ...iconStyle,
          }}
        >
          <p
            style={{
              fontWeight: 600,
              margin: 0,
              marginTop: -1,
              marginLeft: iconSize === "small" && 2,
            }}
          >
            ?
          </p>
        </div>
      }
    >
      <div
        style={{
          maxWidth: large ? 500 : 250,
          padding: "6px 12px",
          borderRadius: 8,
          marginTop: 16,
        }}
      >
        {title && (
          <p
            style={{
              fontSize: 14,
              margin: "6px 0 6px 0",
              fontWeight: 600,
            }}
          >
            {title}
          </p>
        )}
        <StyledParagraph
          style={{
            margin: "2px 0 12px 0",
          }}
        >
          {children}
        </StyledParagraph>
      </div>
    </DropDown>
  );
};

export default InfoDropDown;
