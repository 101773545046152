import axios from "axios";
import { ADD_CATEGORIES } from "./types";

export function getCategories() {
  return (dispatch) => {
    return axios.get("/category").then(
      (res) => {
        dispatch({ type: ADD_CATEGORIES, categories: res.data });
      },
      (err) => {
        console.log("Error getting categories:" + err);
      }
    );
  };
}

export function addCategory(category) {
  alert(category);
  return (dispatch) => {
    return axios.post("/admin/category", category);
  };
}

export function removeCategory(categoryId) {
  alert(categoryId);
  return (dispatch) => {
    return axios.delete("/admin/category/" + categoryId);
  };
}

export function editCategory(category, categoryId) {
  return (dispatch) => {
    return axios.patch("/admin/category/" + categoryId, category);
  };
}
