// Third party
import React from "react";
import Router from "./boiler/Router.jsx";
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { Provider } from "react-redux";
import { clearSession } from "./redux/actions/authActions";
import axios from "axios";

// Custom
import colors from "./constants/Colors";
import common_swe from "./translations/swe/common.json";
import common_en from "./translations/en/common.json";
import { tellusURL } from "./config.js";

// Custom Redux
import store from "./redux/store.js";
import {
  getCurrentUser,
  setCurrentUser,
  refreshAccessToken,
} from "./redux/actions/authActions";
import { FIRST_TIME_AUTH_DONE } from "./redux/actions/types";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.mainGreen,
    },
    secondary: {
      main: colors.error,
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  overrides: {
    MuiButton: {},
  },
});

const GlobalCSS = withStyles({
  "@global": {
    ".MuiStepIcon-text": {
      fill: "white!important",
      fontWeight: 600,
    },
    ".MuiButton-contained": {
      marginTop: 8,
      fontWeight: 600,
      fontSize: 16,
      boxShadow: "none",
      padding: "6px 20px",
      width: "100%",
      color: "white",
      textTransform: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
})(() => null);

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en",
  resources: {
    en: {
      common: common_en,
    },
    swe: {
      common: common_swe,
    },
  },
});

// Set up axios
axios.defaults.baseURL = tellusURL;

// Persistant user website experience
if (localStorage.accessToken) {
  getCurrentUser().then(
    (userData) => {
      // Our access token has not expired, log in
      store.dispatch(setCurrentUser(userData.data));
      store.dispatch({ type: FIRST_TIME_AUTH_DONE });
    },
    () => {
      // New access token needed, refresh it
      refreshAccessToken(store.dispatch).then(
        () => {
          getCurrentUser().then(
            (userData) => {
              // Our access token has not expired, log in
              store.dispatch(setCurrentUser(userData.data));
              store.dispatch({ type: FIRST_TIME_AUTH_DONE });
            },
            (err) => {
              // console.log(
              //  "Something went wrong when getting user: " + err
              // );
              store.dispatch({ type: FIRST_TIME_AUTH_DONE });
            }
          );
        },
        () => {
          // console.log("Refresh token expired, logging out.");
          clearSession();
          store.dispatch({ type: FIRST_TIME_AUTH_DONE });
        }
      );
    }
  );
} else {
  // console.log("No access token in local storage");
  store.dispatch({ type: FIRST_TIME_AUTH_DONE });
}

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={theme}>
          <GlobalCSS />
          <Provider store={store}>
            <Router />
          </Provider>
        </ThemeProvider>
      </I18nextProvider>
    </div>
  );
}

export default App;
