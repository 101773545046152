// Third Party
import React from "react";
import { Grid } from "@material-ui/core";

import StyledDivider from "./StyledDivider";
import InfoDropDown from "./InfoDropDown";
import Colors from "../../constants/Colors";

const DividerTitle = ({ title, dropdown = null, small = false, style }) => (
  <div
    style={{
      fontSize: small ? 16 : 22,
      margin: small ? "22px 0" : 0,
      color: small ? Colors.darkGrey : "black",
      ...style,
    }}
  >
    <Grid container alignItems="center">
      <Grid item>
        <h3 style={{ fontWeight: 600, margin: 0 }}>{title}</h3>
      </Grid>
      {dropdown && (
        <Grid item>
          <InfoDropDown
            title={dropdown.title}
            iconSize="large"
            style={{ float: "left" }}
          >
            {dropdown.content}
          </InfoDropDown>
        </Grid>
      )}
    </Grid>
    <StyledDivider withMargin />
  </div>
);

export default DividerTitle;
