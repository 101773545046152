import React from "react";
import { Logo, Fade } from "./";

// Custom
import "./Loading.css";
import Colors from "../../constants/Colors";

const Loading = ({
  fullScreen,
  firstTime = false,
  justLogo = false,
  style,
}) => {
  if (justLogo) {
    return (
      <div style={{ height: 50, width: "100%", ...style }}>
        <div
          style={{
            margin: "auto",
            width: 50,
            height: 50,
            animation: `spin 6s linear infinite`,
          }}
        >
          <Logo loading />
        </div>
      </div>
    );
  }
  if (firstTime === true) {
    return (
      <div
        style={{
          textAlign: "center",
          flex: 1,
          minHeight: fullScreen ? "100vh" : 0,
          marginTop: fullScreen ? "30vh" : 0,
          ...style,
        }}
      >
        <Fade>
          <div>
            <div>
              <Logo loading />
            </div>
            <p
              style={{
                fontSize: 28,
                margin: 4,
                fontWeight: 600,
                letterSpacing: 2,
                color: Colors.mainGreen,
              }}
            >
              CLMTE
            </p>
          </div>
        </Fade>
      </div>
    );
  } else {
    return (
      <div
        style={{
          textAlign: "center",
          flex: 1,
          minHeight: fullScreen ? "100vh" : 0,
          marginTop: fullScreen ? "30vh" : 0,
          ...style,
        }}
      >
        <div>
          <div
            style={{
              animation: `spin 6s linear infinite`,
            }}
          >
            <Logo loading />
          </div>
          <p
            style={{
              fontSize: 28,
              margin: 4,
              fontWeight: 600,
              letterSpacing: 2,
              color: Colors.mainGreen,
            }}
          >
            CLMTE
          </p>
        </div>
      </div>
    );
  }
};

export default Loading;
