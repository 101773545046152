import React from "react";
import Fade from "react-reveal/Fade";
// Custom
import getWindowSize from "../../util/getWindowSize";
import BreakPoints from "../../constants/BreakPoints";

const BottomFade = ({ fadeOnMobile = false, children }) => {
  const windowSize = getWindowSize();
  if (windowSize.width < BreakPoints.xs) {
    if (fadeOnMobile === true) {
      return <Fade bottom>{children}</Fade>;
    } else {
      return children;
    }
  } else {
    return <Fade bottom>{children}</Fade>;
  }
};

export default BottomFade;
