import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

const LoadingButton = ({
  style,
  loading = false,
  text,
  onClick = () => {},
  type = "submit",
}) => {
  return (
    <Button
      loading
      style={{ margin: 0, ...style }}
      variant="contained"
      color="primary"
      type={type}
      onClick={onClick}
    >
      {loading ? (
        <CircularProgress
          style={{ color: "white", width: 20, height: 20, padding: 4 }}
        />
      ) : (
        text
      )}
    </Button>
  );
};

export default LoadingButton;
