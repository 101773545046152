// Third party
import SimplexNoise from "simplex-noise";

const terrainChunkSize = 32;
const entityMaxSize = 24;

const generateNoiseMap = (inc, noiseMapYLen, noiseMapXLen) => {
  let noise = new SimplexNoise(Math.random());
  let noiseMap = [];

  for (let y = 0; y < noiseMapYLen; y++) {
    noiseMap[y] = [];
    for (let x = 0; x < noiseMapXLen; x++) {
      let value = noise.noise2D(y * inc, x * inc);
      noiseMap[y][x] = value;
    }
  }
  return noiseMap;
};

const getNoise = (noiseMapHeight, noiseMapWidth) => {
  const noiseMapYLen = Math.round(noiseMapHeight / terrainChunkSize);
  const noiseMapXLen = Math.round(noiseMapWidth / terrainChunkSize);
  const heightMapInc = 1 / 30;
  const fertilityMapInc = 1 / 60;

  // Create noisemaps
  let heightMap = generateNoiseMap(heightMapInc, noiseMapYLen, noiseMapXLen);
  let fertilityMap = generateNoiseMap(
    fertilityMapInc,
    noiseMapYLen,
    noiseMapXLen
  );
  let temperatureMap = generateNoiseMap(
    fertilityMapInc,
    noiseMapYLen,
    noiseMapXLen
  );
  let noise = {
    noiseMapHeight: noiseMapHeight,
    noiseMapWidth: noiseMapWidth,

    terrainChunkSize: terrainChunkSize,
    entityMaxSize: entityMaxSize,

    heightMap: heightMap,
    fertilityMap: fertilityMap,
    temperatureMap: temperatureMap,
  };

  return noise;
};

export default getNoise;
