import { ADD_CATEGORIES } from "../actions/types";

const initalState = null;

export default (state = initalState, action = {}) => {
  switch (action.type) {
    case ADD_CATEGORIES:
      return action.categories;
    default:
      return state;
  }
};
