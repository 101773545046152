import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: "none",
    borderRadius: 4,
  },
}));

const TransitionsModal = ({
  open,
  setOpen,
  title = "",
  children,
  style,
  modalProps,
  posAbsoluteHeader = false,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        keepMounted={true}
        BackdropProps={{
          timeout: 500,
        }}
        {...modalProps}
      >
        <Fade in={open}>
          <div
            className={classes.paper}
            style={{ position: "relative", ...style }}
          >
            <Grid
              container
              justify="space-between"
              alignItems="center"
              style={{
                position: posAbsoluteHeader ? "absolute" : "relative",
              }}
            >
              <Grid item style={{ cursor: "pointer", width: 50 }} />
              <Grid item>
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    marginBottom: 0,
                    marginTop: 16,
                  }}
                >
                  {title}
                </p>
              </Grid>
              <Grid item>
                <CloseIcon
                  onClick={() => setOpen(false)}
                  style={{
                    cursor: "pointer",
                    fontSize: 22,
                    marginTop: 20,
                    marginRight: 20,
                  }}
                />
              </Grid>
            </Grid>
            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
