import React from "react";
import logo from "../../assets/smallLogo.png";
import textlogo from "../../assets/website-logo.png";
import smallWhiteLogo from "../../assets/website-logo-small-white.png";

const Logo = ({ smallWhite = false, loading = false }) => {
  if (smallWhite) {
    return <img alt="" src={smallWhiteLogo} />;
  } else if (loading) {
    return <img alt="" src={logo} />;
  } else {
    return <img alt="" src={textlogo} />;
  }
};

export default Logo;
