import React from "react";
import Grid from "@material-ui/core/Grid";

const AlignCenter = ({ children, style, wide = false }) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    justify="center"
    style={style}
  >
    <Grid
      item
      xs={wide ? 12 : 11}
      sm={wide ? 12 : 11}
      md={wide ? 11 : 10}
      lg={wide ? 10 : 8}
      xl={wide ? 8 : 6}
    >
      <Grid container>{children}</Grid>
    </Grid>
  </Grid>
);

export default AlignCenter;
