import { combineReducers } from "redux";
import flashMessages from "./reducers/flashMessages";
import auth from "./reducers/auth";
import organisations from "./reducers/organisations";
import categories from "./reducers/categories";
import products from "./reducers/products";

export default combineReducers({
  categories,
  organisations,
  auth,
  flashMessages,
  products,
});
