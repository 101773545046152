import {
  SET_CURRENT_USER,
  DONE_REFRESHING_TOKEN,
  REFRESHING_TOKEN,
  FIRST_TIME_AUTH_DONE,
} from "../actions/types";
import isEmpty from "lodash/isEmpty";

const initalState = {
  tokenRefreshing: true,
  isAuthenticated: false,
  firstTimeAuthLoading: true,
  user: {},
};

export default (state = initalState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.user),
        user: action.user,
      };
    case DONE_REFRESHING_TOKEN:
      return {
        ...state,
        tokenRefreshing: false,
      };
    case REFRESHING_TOKEN:
      return {
        ...state,
        tokenRefreshing: true,
      };
    case FIRST_TIME_AUTH_DONE:
      return {
        ...state,
        firstTimeAuthLoading: false,
      };
    default:
      return state;
  }
};
