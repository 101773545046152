import axios from "axios";
import {
  ADD_ORGANISATIONS,
  ADD_DETAILED_ORGANISATION,
  ADD_TUNDRA_STATS_TO_ORG,
  ADD_HIGHLAND_COMPENSATIONS_TO_ORG,
} from "./types";

export function createOrganisation(orgData) {
  return (dispatch) => {
    return axios.post("/organisation", orgData);
  };
}

export function deleteOrganisation(id) {
  return (dispatch) => {
    return axios.delete("/organisation/" + id);
  };
}

export function editOrganisation(orgData, id) {
  return (dispatch) => {
    return axios.patch("/organisation/" + id, orgData);
  };
}

export function getOrganisationEmissions(id) {
  return (dispatch) => {
    return axios.get("/organisation/" + id + "/carbon_dioxide");
  };
}

export function getOrganisation(id) {
  return (dispatch) => {
    return axios.get("/organisation/" + id).then(
      (res) => {
        dispatch({
          type: ADD_DETAILED_ORGANISATION,
          detailedOrganisation: res.data,
        });
      },
      () => {}
    );
  };
}

export function getOrganisations() {
  return (dispatch) => {
    return axios.get("/organisation").then(
      (res) => {
        let organisations = res.data;
        dispatch({ type: ADD_ORGANISATIONS, organisations });
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function getPublicPage(identifier) {
  return (dispatch) => {
    return axios.get("/organisation/" + identifier + "/public_page/");
  };
}

export function createHighlandCompensations(orgId, compensation) {
  return (dispatch) => {
    return axios.post(
      "/organisation/" + orgId + "/highland/compensation",
      compensation
    );
  };
}

export function getHighlandCompensations(
  orgId,
  skip,
  limit,
  forceNewFetch = false
) {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      // If we have already loaded the tundra statistics, return them
      let foundDataInStore = false;
      if (forceNewFetch === false) {
        getState().organisations.detailedOrgs.forEach((detailedOrg) => {
          if (detailedOrg._id === orgId) {
            if (detailedOrg.highlandCompensations) {
              foundDataInStore = true;
              resolve(detailedOrg.highlandCompensations);
            }
          }
        });
      }

      if (foundDataInStore === false || forceNewFetch === true) {
        axios
          .get("/organisation/" + orgId + "/highland/compensation", {
            params: {
              skip,
              limit,
            },
          })
          .then(
            (res) => {
              let highlandData = res.data === undefined ? [] : res.data;
              dispatch({
                type: ADD_HIGHLAND_COMPENSATIONS_TO_ORG,
                orgId: orgId,
                highlandCompensations: highlandData,
              });
              resolve(highlandData);
            },
            (err) => {
              reject("Couldn't get highland stats: " + err);
            }
          );
      }
    });
  };
}

export function createTundraAPIKey(orgId, name, expires) {
  return (dispatch) => {
    return axios.post("/organisation/" + orgId + "/tundra/api_key", {
      name: name,
      expires: expires,
    });
  };
}

export function getExistingAPIKeys(orgId) {
  return (dispatch) => {
    return axios.get("/organisation/" + orgId + "/tundra/api_key", {
      params: {
        skip: 0,
        limit: 100,
      },
    });
  };
}

export function patchTundraAPIKey(orgId, apiKeyId, name, expires) {
  return (dispatch) => {
    return axios.patch(
      "/organisation/" + orgId + "/tundra/api_key/" + apiKeyId,
      {
        name: name,
        expires: expires,
      }
    );
  };
}

export function deleteTundraAPIKey(orgId, keyId) {
  return (dispatch) => {
    return axios.delete("/organisation/" + orgId + "/tundra/api_key/" + keyId);
  };
}

export function getTundraStatistics(orgId) {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      // If we have already loaded the tundra statistics, return them
      let foundDataInStore = false;
      getState().organisations.detailedOrgs.forEach((detailedOrg) => {
        if (detailedOrg._id === orgId) {
          if (detailedOrg.tundraStatistics) {
            foundDataInStore = true;
            resolve(detailedOrg.tundraStatistics);
          }
        }
      });

      if (!foundDataInStore) {
        axios
          .get("/organisation/" + orgId + "/tundra/statistic", {
            params: {
              skip: 0,
              limit: 999,
            },
          })
          .then(
            (res) => {
              dispatch({
                type: ADD_TUNDRA_STATS_TO_ORG,
                orgId: orgId,
                tundraStatistics: res.data,
              });
              resolve(res.data);
            },
            (err) => {
              reject("Couldn't get tundra stats: " + err);
            }
          );
      }
    });
  };
}
