import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./rootReducer";
import jwtMiddleware from "./jwtMiddleware";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(jwtMiddleware, thunk),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);

export default store;
